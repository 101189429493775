import { Component, Input, OnChanges } from '@angular/core';
import { User } from '@shared/models/user';
import { NgClass, NgIf, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'packex-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  imports: [NgClass, NgIf, UpperCasePipe],
})
export class UserInfoComponent implements OnChanges {
  @Input() firstName: string | undefined;
  @Input() lastName: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() hideProfilePicture = false;
  @Input() user?: User | null;
  @Input() onlyInitials = false;
  @Input() disabled = false;
  @Input() showEmail = false;

  firstNameChar: string | undefined;
  lastNameChar: string | undefined;

  ngOnChanges(): void {
    let firstName;
    let lastName;
    if (this.user) {
      firstName = this.user.firstName ?? '';
      lastName = this.user.lastName ?? '';
      this.firstName = firstName;
      this.lastName = lastName;
    } else {
      firstName = this.firstName ?? '';
      lastName = this.lastName ?? '';
    }
    this.firstNameChar = firstName.slice(0, 1);
    this.lastNameChar = lastName.slice(0, 1);
  }

  public get currentLoyaltyStatusId(): string {
    const loyaltyDisabled = this.user?.loyaltyStatus?.disabled;

    if (loyaltyDisabled) {
      return '';
    }
    return this.user?.loyaltyStatus?.currentStatus?.id ?? '';
  }
}
